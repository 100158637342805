import Api from "../../api/Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ADD_PLAYSTATION_FAIL,
  ADD_PLAYSTATION_SUCCESS,
  DELETE_PLAYSTATION_SUCCESS,
  GET_PLAYSTATION,
  GET_PLAYSTATION_SUCCESS,
  UPDATE_PLAYSTATION_SUCCESS,
} from "./actionTypes";

export const loadPlaystation = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.get("/playstationall/get");

  toast.promise(req, {
    pending: "Getting Playstation IDs...",
    success: {
      render({ data }) {
        dispatch({
          payload: {
            playstation: data.data.data,
            defective: data.data.defective,
          },
          type: GET_PLAYSTATION_SUCCESS,
        });
        return "Your Data Is Ready";
      },
    },
    error: {
      render({ data }) {
        return "An error occurred while getting playstations IDs";
      },
    },
  });
};

export const addPlaystation = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/playstation/add", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: ADD_PLAYSTATION_SUCCESS,
        });
        return "Playstation ID added successfully";
      },
    },
    error: {
      render({ data }) {
        // return data?.data.response.data?.message;

        return data?.response.data?.message;
      },
    },
  });
};

export const updatePlaystation = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/playstation/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_PLAYSTATION_SUCCESS,
        });
        return "Playstation ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your Playstation ID";
      },
    },
  });
};

export const deletePlaystation = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/playstation/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: DELETE_PLAYSTATION_SUCCESS,
        });
        return "Playstation ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your Playstation ID";
      },
    },
  });
};

export const soldPlaystation = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/playstation/sold", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_PLAYSTATION_SUCCESS,
        });
        return "Playstation ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your Playstation ID";
      },
    },
  });
};

export const importPlaystation = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/playstation/import", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadPlaystation());
        return "Playstation IDs imported successfully";
      },
    },
    error: {
      render({ data }) {
        return data?.response.data?.message;
      },
    },
  });
};

export const exportPlaystation = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/playstation/export", { number: data.formData.number });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadPlaystation());

        window.open(response.data.data, "_blank");
        return "Playstation IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const exportPlaystationQr = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/playstation/export/qr", {
    number: data.number,
    image: data.image,
    shownote: data.shownote,
    note: data.note,
  });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadPlaystation());

        window.open(response.data.data, "_blank");
        return "Playstation IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const fixApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/playstation/fix", { accounts: data.formData.accounts });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadPlaystation());

        // window.open(response.data.data, "_blank");
        return "Apple IDs fixed successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const fixApplePublic = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/playstation/fix", { accounts: data.formData.accounts });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        window.location.reload();
        return "playstation IDs fixed successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const deleteAppleDefective = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/playstation/delete/defective", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadPlaystation());
        return "playstation ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your playstation ID";
      },
    },
  });
};

export const exportAppleDefective = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/playstation/export/defective");

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadPlaystation());

        window.open(response.data.data, "_blank");
        return "Playstation IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const exportAppleSold = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/playstation/export/sold");

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        window.open(response.data.data, "_blank");
        return "Exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};
