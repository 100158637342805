import Api from "../../api/Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ADD_INSTAGRAM_FAIL,
  ADD_INSTAGRAM_SUCCESS,
  DELETE_INSTAGRAM_SUCCESS,
  GET_INSTAGRAM,
  GET_INSTAGRAM_SUCCESS,
  UPDATE_INSTAGRAM_SUCCESS,
} from "./actionTypes";

export const loadInstagram = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.get("/instagramall/get");

  toast.promise(req, {
    pending: "Getting Instagram IDs...",
    success: {
      render({ data }) {
        dispatch({
          payload: {
            instagram: data.data.data,
            defective: data.data.defective,
          },
          type: GET_INSTAGRAM_SUCCESS,
        });
        return "Your Data Is Ready";
      },
    },
    error: {
      render({ data }) {
        return "An error occurred while getting instagrams IDs";
      },
    },
  });
};

export const addInstagram = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/instagram/add", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: ADD_INSTAGRAM_SUCCESS,
        });
        return "Instagram ID added successfully";
      },
    },
    error: {
      render({ data }) {
        // return data?.data.response.data?.message;

        return data?.response.data?.message;
      },
    },
  });
};

export const updateInstagram = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/instagram/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_INSTAGRAM_SUCCESS,
        });
        return "Instagram ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your Instagram ID";
      },
    },
  });
};

export const deleteInstagram = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/instagram/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: DELETE_INSTAGRAM_SUCCESS,
        });
        return "Instagram ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your Instagram ID";
      },
    },
  });
};

export const soldInstagram = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/instagram/sold", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_INSTAGRAM_SUCCESS,
        });
        return "Instagram ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your Instagram ID";
      },
    },
  });
};

export const importInstagram = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/instagram/import", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadInstagram());
        return "Instagram IDs imported successfully";
      },
    },
    error: {
      render({ data }) {
        return data?.response.data?.message;
      },
    },
  });
};

export const exportInstagram = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/instagram/export", { number: data.formData.number });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadInstagram());

        window.open(response.data.data, "_blank");
        return "Instagram IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const exportInstagramQr = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/instagram/export/qr", {
    number: data.number,
    image: data.image,
    shownote: data.shownote,
    note: data.note,
  });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadInstagram());

        window.open(response.data.data, "_blank");
        return "Instagram IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const fixApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/instagram/fix", { accounts: data.formData.accounts });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadInstagram());

        // window.open(response.data.data, "_blank");
        return "Apple IDs fixed successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const fixApplePublic = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/instagram/fix", { accounts: data.formData.accounts });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        window.location.reload();
        return "Apple IDs fixed successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const deleteAppleDefective = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/instagram/delete/defective", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadInstagram());
        return "Apple ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your Apple ID";
      },
    },
  });
};

export const exportAppleDefective = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/instagram/export/defective");

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadInstagram());

        window.open(response.data.data, "_blank");
        return "Instagram IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const exportAppleSold = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/instagram/export/sold");

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        window.open(response.data.data, "_blank");
        return "Exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};
