import Api from "../../api/Api";
import { toast } from "react-toastify";
import axios from "axios";
import {
  ADD_FACEBOOK_FAIL,
  ADD_FACEBOOK_SUCCESS,
  DELETE_FACEBOOK_SUCCESS,
  GET_FACEBOOK,
  GET_FACEBOOK_SUCCESS,
  UPDATE_FACEBOOK_SUCCESS,
} from "./actionTypes";

export const loadFacebook = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.get("/facebookall/get");

  toast.promise(req, {
    pending: "Getting Facebook IDs...",
    success: {
      render({ data }) {
        dispatch({
          payload: { facebook: data.data.data, defective: data.data.defective },
          type: GET_FACEBOOK_SUCCESS,
        });
        return "Your Data Is Ready";
      },
    },
    error: {
      render({ data }) {
        return "An error occurred while getting facebooks IDs";
      },
    },
  });
};

export const addFacebook = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/facebook/add", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: ADD_FACEBOOK_SUCCESS,
        });
        return "Facebook ID added successfully";
      },
    },
    error: {
      render({ data }) {
        // return data?.data.response.data?.message;

        return data?.response.data?.message;
      },
    },
  });
};

export const updateFacebook = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/facebook/update", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_FACEBOOK_SUCCESS,
        });
        return "Facebook ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your Facebook ID";
      },
    },
  });
};

export const deleteFacebook = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/facebook/delete", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: DELETE_FACEBOOK_SUCCESS,
        });
        return "Facebook ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your Facebook ID";
      },
    },
  });
};

export const soldFacebook = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/facebook/sold", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch({
          payload: data.data.data,
          type: UPDATE_FACEBOOK_SUCCESS,
        });
        return "Facebook ID updated successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while updating your Facebook ID";
      },
    },
  });
};

export const importFacebook = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/facebook/import", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadFacebook());
        return "Facebook IDs imported successfully";
      },
    },
    error: {
      render({ data }) {
        return data?.response.data?.message;
      },
    },
  });
};

export const exportFacebook = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/facebook/export", { number: data.formData.number });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadFacebook());

        window.open(response.data.data, "_blank");
        return "Facebook IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const exportFacebookQr = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/facebook/export/qr", {
    number: data.number,
    image: data.image,
    shownote: data.shownote,
    note: data.note,
  });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadFacebook());

        window.open(response.data.data, "_blank");
        return "Facebook IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const fixApple = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/facebook/fix", { accounts: data.formData.accounts });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadFacebook());

        // window.open(response.data.data, "_blank");
        return "Apple IDs fixed successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const fixApplePublic = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/facebook/fix", { accounts: data.formData.accounts });

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        window.location.reload();
        return "Facebook IDs fixed successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const deleteAppleDefective = (data) => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  let req = Api.post("/facebook/delete/defective", data);

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render({ data }) {
        dispatch(loadFacebook());
        return "Apple ID deleted successfully";
      },
    },
    error: {
      render({ data }) {
        console.log(data);
        return "An error occurred while deleting your Apple ID";
      },
    },
  });
};

export const exportAppleDefective = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/facebook/export/defective");

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        dispatch(loadFacebook());

        window.open(response.data.data, "_blank");
        return "Facebook IDs exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};

export const exportAppleSold = () => (dispatch, getState) => {
  Api.defaults.headers.common["Authorization"] =
    "Bearer " + getState().login.token;
  // Api.defaults.headers.common["Content-Type"] = "multipart/form-data";
  let req = Api.post("/facebook/export/sold");

  toast.promise(req, {
    pending: "Loading...",
    success: {
      render(response) {
        window.open(response.data.data, "_blank");
        return "Exported successfully";
      },
    },
    error: {
      render(err) {
        return err?.data.response.data.error;
      },
    },
  });
};
