import React from "react";

import { Col, Modal, Row, Form } from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { exportAppleDefective } from "../../../store/google/actions";
import { useDispatch } from "react-redux";

const schema = yup.object().shape({
  number: yup.number().max(100).required(),

  //   note: yup.string(),
  //   image: yup.mixed().required(),
  // shownote: yup.bol().required(),
});

export default function AppleExportDefective({
  modal_export,
  tog_export,
  setmodal_export,
}) {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (formData) => {
    dispatch(exportAppleDefective({ formData }));

    if (!Object.keys(errors).length) {
      //   reset();
      setmodal_export(false);
    }
  };

  return (
    <Modal
      isOpen={modal_export}
      toggle={() => {
        tog_export();
      }}
      centered
    >
      <div className="modal-header">
        <h5
          className="modal-title mt-0"
          style={{ display: "flex", alignItems: "center" }}
        >
          <i class="ri-apple-fill" style={{ paddingRight: 5 }}></i> Export Apple
          Defective IDs
        </h5>
        <button
          type="button"
          onClick={() => {
            setmodal_export(false);
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-2">
            <Col md="12" className="mb-2">
              {/* <Input bsSize="lg" type="email" placeholder="Apple ID" /> */}
              <div className="field">
                <input
                  {...register("number")}
                  className={`form-control ${
                    errors.number ? "is-invalid" : null
                  }`}
                  placeholder=""
                  type="number"
                />
                <span class="label">Enter Number</span>
              </div>
            </Col>
          </Row>

          <div className="d-flex flex-wrap gap-2 justify-content-center">
            <button
              type="button"
              className="btn btn-lg btn-secondary"
              onClick={() => {
                setmodal_export(false);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              //   onClick={() => {
              //     setmodal_center(false);
              //   }}
              className="btn btn-lg btn-primary"
            >
              Export
            </button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
